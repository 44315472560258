import React, { useState, useEffect } from 'react'
import { Modal, Row } from 'react-bootstrap'
import Chip from '../components/Chip'
function ViewComment(props) {
    const [data, setData] = useState()
    useEffect(() => {
        if (props.item.feedback) {
            setData(props.item.feedback)
        }
    }, [props.item])
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: 400, overflow: "scroll" }}>
                <strong>Students</strong>
                <Row style={{padding:"0px 8px 0px 8px"}}>
                    {data ? JSON.parse(data.students).map((item, index) => (
                        <Chip item={item} disabled />
                    ))
                        : null
                    }
                </Row>
                <strong>Comments</strong>
                <pre style={{ whiteSpace: "pre-wrap" }}>{data ? data.comments : null}</pre>
            </Modal.Body>
        </Modal>
    )
}

export default ViewComment