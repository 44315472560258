import axios from 'axios';
const url = "http://api.pointcooktutoring.com/api"
    // const url = "http://localhost:8000/api"
const makeRequest = (token, contentType) => {
    let headers;
    if (contentType) {
        headers = {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            'content-type': contentType
        }
    } else {
        headers = {
            Authorization: "Bearer " + token,
            Accept: "application/json",
        }
    }
    const Api = axios.create({
        baseURL: url,
        headers: headers
    });
    return (Api)
}
export default makeRequest