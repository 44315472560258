import React, { useEffect, useState } from 'react'
import { Container, Card, Row, ListGroup, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getSessionsData } from '../actions/sessionActions'
import Loader from '../components/Loader'
import store from '../utils/store'
import Sessions from './Sessions'
import Writing from './Writing'
function Dashboard() {
    const data = useSelector(state => state.sessions)
    const [index, setIndex] = useState(0)
    return (
        <div>
            {data.loading ? <Loader /> : null}

            <Container>
                <div style={{ marginTop: '16px', display: "flex", flexDirection: "row" }}>
                    <div
                        onClick={() => setIndex(0)}
                        style={index === 0 ? { borderBottom: "1px solid black", cursor: "pointer" } : { cursor: "pointer" }}>
                        <strong>Sessions</strong>
                    </div>
                    <div style={{ padding: "16px" }} />
                    <div
                        onClick={() => setIndex(1)}
                        style={index === 1 ? { borderBottom: "1px solid black", cursor: "pointer" } : { cursor: "pointer" }}>
                        <strong>Writing</strong>
                    </div>
                </div>
                {
                    index === 0 ?
                        data.sessions ?
                            <Sessions />
                            :
                            null
                        :
                        <Writing />
                }
            </Container>
        </div>
    )
}

export default Dashboard
