import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './screens/Dashboard';
import Login from './screens/Login'
import Logout from './screens/Logout'
import { Nav, Navbar } from 'react-bootstrap';
toast.configure();
function App() {
  const user = useSelector(state => state.user);
  const AuthorizedRoute = ({ component: Component, ...routeProps }) =>
    (
      <Route {...routeProps} render={(props) => user.isLoggedIn ? (<Component {...props} />) : (<Redirect to="/login" />)} />
    );

  return (
    <div>
      {user.isLoggedIn ?
        <Navbar bg="white" expand="lg" className="shadow" fixed>
          <Navbar.Brand>{user.name}</Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link href="/logout">Logout</Nav.Link>
          </Nav>
        </Navbar>
        : null}
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" exact component={Login} />
          <Route path="/logout" component={Logout} />
          <AuthorizedRoute path="/dashboard" component={Dashboard} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
