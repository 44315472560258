export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS"
export const LOGIN_REQUEST_FAILURE = "LOGIN_REQUEST_FAILURE"
export const LOG_OUT = "LOGOUT"
export const GET_SESSIONS = "GET_SESSIONS"
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS"
export const GET_SESSIONS_FAILURE = "GET_SESSIONS_FAILURE"
export const UPDATE_SESSIONS = "UPDATE_SESSIONS"
export const GET_SUBMISSIONS = "GET_SUBMISSIONS"
export const GET_SUBMISSIONS_SUCCESS = "GET_SUBMISSIONS_SUCCESS"
export const GET_SUBMISSIONS_FAILURE = "GET_SUBMISSIONS_FAILURE"
export const UPDATE_SUBMISSIONS = "UPDATE_SUBMISSIONS"
export const UPDATE_SUBMISSIONS_SUCCESS = "UPDATE_SUBMISSIONS_SUCCESS"
export const UPDATE_SUBMISSIONS_FAILURE = "UPDATE_SUBMISSIONS_FAILURE"
export const GET_STUDENT_SUBMISSIONS = "GET_STUDENT_SUBMISSIONS"
export const GET_STUDENT_SUBMISSIONS_SUCCESS = "GET_SUBMISSIONS_STUDENT_SUCCESS"
export const GET_STUDENT_SUBMISSIONS_FAILURE = "GET_SUBMISSIONS_STUDENT_FAILURE"
export const SET_LOADING_ITEM = "SET_LOADING_ITEM"