import * as t from "../actions/types"
const initialState = {
    loading: false,
    sessions: null,
    students: []
}

export default function sessionsReducer(state = initialState, action) {
    switch (action.type) {
        case t.GET_SESSIONS:
            return {
                ...state,
                loading: true
            }
        case t.GET_SESSIONS_SUCCESS:
            return {
                loading: false,
                sessions: action.payload.data,
                students: action.payload.students
            }
        case t.GET_SESSIONS_FAILURE:
            return {
                ...state,
                loading: false
            }
        case t.UPDATE_SESSIONS:
            return {
                ...state,
                sessions: action.payload
            }
        default:
            return state
    }
}