import * as t from "../actions/types"
const initialState = {
    isLoggedIn: false,
    isLoading: false,
    token: "",
    error: false,
    errorMessage: "",
    name:"",
    live:0,
    type:""
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case t.LOGIN_REQUEST:
            return {
                ...state,
                isLoading: true
            }
        case t.LOGIN_REQUEST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                token: action.payload.access_token,
                isLoggedIn: true,
                error: false,
                errorMessage: "",
                name:action.payload.name,
                live:action.payload.live,
                type:action.payload.type
            }
        case t.LOGIN_REQUEST_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                isLoading: false,
                error: true,
                errorMessage: action.payload.message,
                name:"",
                live:0
            }
        case t.LOG_OUT:
            return {
                ...state,
                isLoggedIn: false,
                isLoading: false,
                error: false,
                errorMessage: "",
                name:"",
                live:0
            }
        default:
            return state
    }
}