import * as t from "../actions/types"
const initialState = {
    loading: false,
    submissions: null,
    loadingItem: 0
}

export default function submissionsReducer(state = initialState, action) {
    switch (action.type) {
        case t.GET_SUBMISSIONS:
            return {
                ...state,
                loading: true
            }
        case t.GET_SUBMISSIONS_SUCCESS:
            return {
                loading: false,
                submissions: action.payload.data,
            }
        case t.GET_SUBMISSIONS_FAILURE:
            return {
                ...state,
                loading: false
            }
        case t.GET_STUDENT_SUBMISSIONS:
            return {
                ...state,
                loading: true
            }
        case t.GET_STUDENT_SUBMISSIONS_SUCCESS:
            return {
                loading: false,
                submissions: action.payload.data,
            }
        case t.GET_STUDENT_SUBMISSIONS_FAILURE:
            return {
                ...state,
                loading: false
            }
        case t.SET_LOADING_ITEM:
            return {
                ...state,
                loadingItem: action.payload
            }
        case t.UPDATE_SUBMISSIONS_SUCCESS:
            let items = [...state.submissions]
            let item = items.find(x => x.id === action.payload.data.id)
            item.test_data = action.payload.data.test_data
            return {
                ...state,
                loadingItem: 0,
                submissions: items,

            }
        default:
            return state
    }
}