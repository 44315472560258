import * as t from './types'
import makeRequest from '../utils/makeRequest';
import store from '../utils/store';
import ShowToast from '../utils/ShowToast'
export const getSubmissions = ({
    type: t.GET_SUBMISSIONS
})

export const getSubmissionsSuccess = data => ({
    type: t.GET_SUBMISSIONS_SUCCESS,
    payload: data
})

export const getSubmissionsFailure = ({
    type: t.GET_SUBMISSIONS_FAILURE,
})

export const getStudentSubmissions = ({
    type: t.GET_STUDENT_SUBMISSIONS
})

export const getStudentSubmissionsSuccess = data => ({
    type: t.GET_STUDENT_SUBMISSIONS_SUCCESS,
    payload: data
})

export const getStudentSubmissionsFailure = ({
    type: t.GET_STUDENT_SUBMISSIONS_FAILURE,
})

export const setLoadingItem = data => ({
    type: t.SET_LOADING_ITEM,
    payload: data
})
export const updateSubmission = ({
    type: t.UPDATE_SUBMISSIONS
})

export const updateSubmissionSuccess = data => ({
    type: t.UPDATE_SUBMISSIONS_SUCCESS,
    payload: data
})

export const updateSubmissionFailure = ({
    type: t.UPDATE_SUBMISSIONS_FAILURE,
})
export const getSubmissionData = (token) => {
    return (dispatch) => {
        dispatch(getSubmissions)
        return makeRequest(token).post("/getWritingData")
            .then(res => {
                if (res.data.status_code === 200) {
                    dispatch(getSubmissionsSuccess(res.data))
                } else {
                    ShowToast("error", res.data.message)
                }
            })
            .catch(err => {
                ShowToast('error', "Something went wrong!")
                dispatch(getSubmissionsFailure)
            })

    }
}
export const getStudentSubmissionData = (token, data) => {
    return (dispatch) => {
        dispatch(getStudentSubmissions)
        return makeRequest(token).post("/getStudentWritingData", data)
            .then(res => {
                if (res.data.status_code === 200) {
                    dispatch(getStudentSubmissionsSuccess(res.data))
                } else {
                    ShowToast("error", res.data.message)
                }
            })
            .catch(err => {
                ShowToast('error', "Something went wrong!")
                dispatch(getStudentSubmissionsFailure)
            })

    }
}
export const updateSubmissionData = (token, data) => {
    return (dispatch) => {
        dispatch(updateSubmission)
        return makeRequest(token,'multipart/form-data').post("/updateSubmission", data)
            .then(res => {
                if (res.data.status_code === 200) {
                    dispatch(updateSubmissionSuccess(res.data))
                } else {
                    ShowToast("error", res.data.message)
                }
            })
            .catch(err => {
                ShowToast('error', "Something went wrong!")
                dispatch(updateSubmissionFailure)
            })

    }
}