import * as t from './types'
import makeRequest from '../utils/makeRequest';
import store from '../utils/store';
import ShowToast from '../utils/ShowToast'
export const getSessions = ({
    type: t.GET_SESSIONS
})

export const getSessionsSuccess = data => ({
    type: t.GET_SESSIONS_SUCCESS,
    payload: data
})

export const getSessionsFailure = ({
    type: t.GET_SESSIONS_FAILURE,
})
export const updateSessions = data => ({
    type: t.UPDATE_SESSIONS,
    payload: data
})
export const getSessionsData = (token) => {
    return (dispatch) => {
        dispatch(getSessions)
        return makeRequest(token).post("/getTutorSessions")
            .then(res => {
                if (res.data.status_code === 200) {
                    dispatch(getSessionsSuccess(res.data))
                } else {
                    ShowToast("error", res.data.message)
                }
            })
            .catch(err => {
                ShowToast('error', "Something went wrong!")
                dispatch(getSessionsFailure)
            })

    }
}
