import React from 'react'
import { Row, Container, Col } from 'react-bootstrap'

function Suggestion({ item, onItemSelected }) {
    return (
        <Container className="suggestion" onClick={() => { onItemSelected(item) }}>
            <Row className="align-items-center ml-1">
                <div className="initials">
                    <h5>{item.name.split(" ").map((n)=>n[0]).join("")}</h5>
                </div>
                <Col>
                    <strong>{item.name}</strong>
                    <p>{item.username}</p>
                </Col>
            </Row>
        </Container>
    )
}

export default Suggestion
