import Feedback from './Feedback'
import ViewComment from './ViewComment'
import React, { useEffect, useState } from 'react'
import { Container, Card, Row, ListGroup, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getSessionsData } from '../actions/sessionActions'
import Loader from '../components/Loader'
import store from '../utils/store'
export default function Sessions() {
    const dispatch = useDispatch()
    const [showDialog, setShowDialog] = useState(false)
    const [selected, setSelected] = useState(false)
    const [formatted, setFormatted] = useState([])
    const [show, setShow] = useState(false)
    const [viewSelected, setViewSelected] = useState({})
    const [week, setWeek] = useState()
    useEffect(() => {
        dispatch(getSessionsData(store.getState().user.token))
    }, [])
    const data = useSelector(state => state.sessions)
    return (
        <div>
            <Feedback
                show={showDialog}
                onHide={() => { setShowDialog(false) }}
                item={selected} />
            <ViewComment
                show={show}
                onHide={() => { setShow(false) }}
                item={viewSelected}
            />
            <Card className="shadow rounded mt-4">
                <Card.Header>
                    <Card.Title>Sessions</Card.Title>
                </Card.Header>
                <Card.Body>
                    <Form.Control as="select" onChange={(event) => { setWeek(event.target.value) }} value={week}>
                        <option value={null}>Please select week</option>
                        {Object.keys(data.sessions)?.map((item, index) => {
                            return (
                                <option value={item} key={`week${index}`}>{`Week ${item}`}</option>
                            )
                        })}
                    </Form.Control>
                    <Row style={{ display: 'flex', justifyContent: "space-around" }} className="mt-2">
                        {week ?
                            Object.keys(data.sessions[week])?.map((item, index) => {
                                if (data.sessions[week][item].length == 0) return
                                return (
                                    <Card border="light" style={{ width: '18rem' }} className="m-2 shadow-sm p-1 mb-5 bg-white rounded" key={String(index)}>
                                        <Card.Body>
                                            <Card.Title>{item}</Card.Title>
                                            <ListGroup>
                                                {data.sessions[week][item].map((element, index) => (
                                                    element.feedback == null ?
                                                        <ListGroup.Item
                                                            action
                                                            key={`item${index}`}
                                                            onClick={() => {
                                                                setSelected(element)
                                                                setShowDialog(true)
                                                            }}>
                                                            {element.name}
                                                        </ListGroup.Item> :
                                                        <ListGroup.Item
                                                            variant="success"
                                                            action
                                                            key={`item${index}`}
                                                            onClick={() => {
                                                                setViewSelected(element)
                                                                setShow(true)
                                                            }}>
                                                            {element.name}
                                                        </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Card.Body>
                                    </Card >
                                )
                            })
                            : null}
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}
