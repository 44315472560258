import React, { useEffect, useState } from 'react'
import { Form, ListGroup, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getStudentSubmissionData, getSubmissionData } from '../actions/writingActions'
import DataRow from '../components/DataRow'
import store from '../utils/store'

export default function Writing() {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getSubmissionData(store.getState().user.token))
    }, [])
    const submissions = useSelector(state => state.submissions.submissions)
    const students = useSelector(state => state.sessions.students)
    const [searched, setSearched] = useState([])
    const search = (e) => {
        let s = []
        let query = e.target.value.toLowerCase()
        if (query.length > 0) {
            students.forEach(element => {
                let name = element.name.toLowerCase()
                if (name.indexOf(query) > -1) {
                    s.push(element)
                }
            });
        } else {
            dispatch(getSubmissionData(store.getState().user.token))
        }
        setSearched(s)
    }
    const getStudentData = (id) => {
        let data = new FormData()
        data.append('id', id)
        dispatch(getStudentSubmissionData(store.getState().user.token, data))
    }
    return (
        <div style={{ marginTop: "16px" }}>
            <div style={{ width: 400, marginBottom: 16 }}>
                <OverlayTrigger
                    trigger='focus'
                    key={'bottom'}
                    placement="bottom"
                    overlay={
                        <Popover id={`popover`} style={{ width: 400, maxHeight: 300, overflow: "scroll" }}>
                            <Popover.Content>
                                <ListGroup as="ul">
                                    {searched.map(x => (
                                        <ListGroup.Item style={{cursor:"pointer"}} as="li" onClick={() => getStudentData(x.id)}>{x.name} (Grade-{x.grade_id})</ListGroup.Item>
                                    ))}
                                </ListGroup>

                            </Popover.Content>
                        </Popover>
                    }>
                    <Form.Control placeholder="Search student" onChange={e => search(e)} />
                </OverlayTrigger>
            </div>
            <Table bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Assignment</th>
                        <th>File</th>
                        <th>Update</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {submissions?.map(x => (
                        <DataRow item={x} />
                    ))}
                </tbody>
            </Table>
        </div >
    )
}
