import React, { useState, useRef, useEffect } from 'react'
import { Modal, Form, Row } from 'react-bootstrap'
import BtnDark from '../components/BtnDark'
import ShowToast from '../utils/ShowToast'
import makeRequest from '../utils/makeRequest'
import store from '../utils/store'
import { useSelector, useDispatch } from 'react-redux'
import { updateSessions } from '../actions/sessionActions'
import '../css/feedback.css'
import Suggestion from '../components/Suggestion'
import Chip from '../components/Chip'
function Feedback(props) {
    const [comments, setComments] = useState("")
    const [loading, setLoading] = useState(false)
    const sessions = useSelector(state => state.sessions)
    const [chips, setChips] = useState([])
    const [name, setName] = useState("")
    const [suggestions, setSuggestions] = useState([])
    const chipInput = useRef()
    const dispatch = useDispatch()
    const addFeedback = () => {
        setLoading(true)
        let data = new FormData()
        data.append('session_id', props.item.id)
        data.append('comments', comments)
        data.append('students',JSON.stringify(chips))
        makeRequest(store.getState().user.token).post('/addFeedback', data).then(res => {
            setLoading(false)
            if (res.data.status_code == 200) {
                ShowToast('success', res.data.message)
                dispatch(updateSessions(res.data.data))
                setComments("")
                setChips([])
                props.onHide()
            } else {
                ShowToast('error', res.data.message)
            }
        }).catch(err => {
            ShowToast('error', "Something went wrong!")
            setLoading(false)
        })
    }
    const onChipSelect = (item) => {
        let alreadyExsits = chips.find(x => x.username == item.username)
        if (alreadyExsits) {
            ShowToast("error", "Student already selected")
        } else {
            setChips([...chips, item])
            setName("")
            chipInput.current.focus()
        }
    }
    const onChipRemove = (username) => {
        let newChips = chips.filter(x => x.username != username)
        setChips(newChips)
    }
    useEffect(() => {
        if (name.length > 0) {
            let newSuggestions = []
            sessions.students.forEach(element => {
                if (element.name == "Test Account") return
                if (element.name.toLowerCase().indexOf(name) >= 0) {
                    newSuggestions.push(<Suggestion item={element} key={String(element.id)} onItemSelected={(item) => { onChipSelect(item) }} />)
                }
            });
            setSuggestions(newSuggestions)
        }
    }, [name])
    return (
        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Students</Form.Label>
                        <Row style={{ marginLeft: 1, marginRight: 1, border: "1px solid black", borderRadius: 100, overflow: "hidden" }} className="pl-3">
                            {chips.map((item, index) => (
                                <Chip item={item} key={`Chip${index}`} onRemove={(username) => { onChipRemove(username) }} />
                            ))}
                            <input placeholder="Student name" className="myInput" onChange={(event) => { setName(event.target.value) }} value={name} ref={chipInput} />
                        </Row>
                        <div style={name.trim() == "" ? { display: "none" } : { display: "block" }} className="suggestiondiv shadow rounded">
                            {suggestions}
                        </div>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Comments</Form.Label>
                        <Form.Control value={comments} onChange={(event) => { setComments(event.target.value) }} as="textarea" rows={20} />
                    </Form.Group>
                </Form>
                <BtnDark title="Submit" loading={loading} onClick={(event) => {
                    event.preventDefault()
                    if (comments.trim() != "") {
                        addFeedback()
                    } else {
                        ShowToast('error', "Please enter/select all the fields")
                    }
                }} />
            </Modal.Body>
        </Modal>
    )
}
document.onkeydown = checkKey;

function checkKey(e) {
    e = e || window.event;
    if (e.keyCode == '38') {
        // up arrow
        document.getElementById("#suggestions")[0].hover()
    }
    else if (e.keyCode == '40') {
        // down arrow
    }
}
export default Feedback
