
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingItem, updateSubmissionData } from '../actions/writingActions';
import store from '../utils/store';
import { url } from '../utils/constants'
import { Spinner } from 'react-bootstrap';
function DataRow({ item }) {
    let data = JSON.parse(item.test_data);
    const dispatch = useDispatch()
    const loadingItem = useSelector(state => state.submissions.loadingItem)
    function update(event) {
        let formData = new FormData()
        formData.append("id", item.id)
        formData.append("data", item.test_data)
        formData.append("upload", event.target.files[0])
        formData.append("filename", `${item.user_id}-${item.test_id}-${Object.keys(data)[0]}`)
        dispatch(setLoadingItem(item.id))
        dispatch(updateSubmissionData(
            store.getState().user.token,
            formData
        ))
        event.target.value = ""
    }
    return (
        <tr>
            <td>{item.user_name}</td>
            <td>{item.name}</td>
            <td><a href={`${url}${data[Object.keys(data)[0]]}`}>Download</a></td>
            <td>
                <input
                    type="file"
                    disabled={(loadingItem === item.id) ? true : false}
                    onChange={update}
                    accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
            </td>
            {(loadingItem === item.id) ?
                <td>
                    <Spinner animation="border" />
                </td>
                :
                <td>
                    {data.checked ?
                        <img src={require('../assets/tick.png')} />
                        :
                        <img src={require('../assets/remove.png')} />
                    }
                </td>}
        </tr>
    )
}

export default DataRow
